import Vue from 'vue';
import Router from 'vue-router';
// import BlankView from "../layout/BlankView.vue";
// import * as supermarketh5 from "@ttr-cloud-portalh5/supermarketh5";
import * as fitnessh5 from "@ttr-cloud-portalwxh5s/fitnessh5";
Vue.use(Router);
const routes =   [
  {
    path: '',
    redirect: 'login'
  },
  // ...supermarketh5.router.options.routes,
  ...fitnessh5.router.options.routes,
  // {
  //   path: '/index',
  //   name: '首页',
  //   component: () => import("../pages/index")
  // },
  // {
  //   path: '',
  //   name: '父级路由1',
  //   meta: {
  //     icon: 'dashboard',
  //   },
  //   component: BlankView,
  //   children: [
  //     {
  //       path: 'demo1',
  //       name: '演示页面1',
  //       component: () => import('../pages/advertisingH5'),
  //     }
  //   ]
  // },


];


// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({  mode: 'history', routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};