import Vuex, { Store } from 'vuex'
import Vue from 'vue'
import getters from './getters'
import auth from './modules/auth'

Vue.use(Vuex)

const store = new Store({
  modules: {
    auth
  },
  getters
})

export default store
