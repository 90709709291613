// import moment from 'moment'
// import Cookies from 'js-cookie'



export default {
  state: () => ({
    submitData: JSON.parse(window.sessionStorage.getItem("submitData")) , 
    userInfo: JSON.parse(window.sessionStorage.getItem("userInfo")) , 
    
  }),
  mutations: {
    SER_SUBMIT_DATA(state, submitData) {
      state.submitData = submitData
      if (!submitData) {
        window.sessionStorage.removeItem('submitData')
      } else {
        window.sessionStorage.setItem('submitData',  JSON.stringify(submitData), {})
      }
    },
    SER_USER_INFO(state, userInfo) {
      state.userInfo = userInfo
      if (!userInfo) {
        window.sessionStorage.removeItem('userInfo')
      } else {
        window.sessionStorage.setItem('userInfo',  JSON.stringify(userInfo), {})
      }
    },
  },
  actions: {
    async setsubmitData({ commit }, submitData) {
      commit('SER_SUBMIT_DATA', submitData)
    },
    async setuserInfo({ commit }, userInfo) {
      commit('SER_USER_INFO', userInfo)
    },
  }
}
