import req from '@ttr-cloud-portalh5/platform/src/utils/request.js'
import { Notify } from 'vant'
// todo 请求接口
export default {
    //列表
    aifaceGet(url,param, cb, errorCb) {
      req.get(url, param).then(res => {

          if (res.status&&res.status == '00') {
            // 成功
               cb(res)
           }else{
                   // 失败
                Notify({ type: 'warning', message: res.header.msg });
            }
      }).catch(function (error) {

          errorCb && errorCb(error.message)
      })
    },

    aifacePost(url,param, cb, errorCb){
        req.post(url, param).then(res => {
            if (res.status&&res.status == '00') {
         // 成功
            cb(res)
        }else{
            console.log('res',res);
                // 失败
             Notify({ type: 'warning', message: res.header.msg });
         }
        }).catch(function (error) {
            //  Notify({ type: 'warning', message: error });
             errorCb && errorCb(error.message)
        })
    },
}
